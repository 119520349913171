<template>
  <common-trs-page-container
    :padding="0"
    :margin="0"
  >
    <template v-slot:page-content>
      <common-app-page-header-v2
        icon="icon-access-control"
        title="Access Control"
      />
      <div style="padding: 24px;">
        <common-trs-breadcrumb
          show-back
          :items="[{label: 'User List', route: '/settings/access'}]"
        />
      </div>
      <v-divider style="margin-bottom: 12px;" />
      <div class="d-flex">
        <common-trs-page-nav-vertical>
          <common-tab
            :to="{ name: 'UserProfileDetails' }"
            class="text-body-2 font-weight-bold"
          >
            Profile
          </common-tab>
          <common-tab
            :to="{ name: 'UserAccess' }"
            class="text-body-2 font-weight-bold"
          >
            Access
          </common-tab>
        </common-trs-page-nav-vertical>
        <div class="flex-fill mx-md-6 my-md-3">
          <v-col v-if="loading">
            <v-progress-circular
              class="ml-2"
              color="primary"
              indeterminate
            />
          </v-col>
          <router-view />
        </div>
      </div>
    </template>
  </common-trs-page-container>
</template>

<script>
  export default {
    name: 'CaptableUserDetails',

    data: () => ({
      loading: false,
    }),
  }
</script>
